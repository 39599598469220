document.addEventListener("DOMContentLoaded", function () {
  const sameAsHeadOfficeCheckbox = document.getElementById("same_as_head_office");

  if (sameAsHeadOfficeCheckbox) {
    sameAsHeadOfficeCheckbox.addEventListener("change", function () {
      const headOfficeAddressLine1 = document.getElementById('head_office_address_address_line_1').value;
      const headOfficeAddressLine2 = document.getElementById('head_office_address_address_line_2').value;
      const headOfficeCountry = document.getElementById('head_office_address_country').value;
      const headOfficeState = document.getElementById('head_office_address_state').value;
      const headOfficeCity = document.getElementById('head_office_address_city').value;
      const headOfficePostCode = document.getElementById('head_office_address_post_code').value;
      const headOfficePhone = document.getElementById('head_office_address_phone_number').value;
      const headOfficeAlternatePhone = document.getElementById('head_office_address_alternate_phone_number').value;

      const personalAddressLine1 = document.getElementById('personal_address_address_line_one');
      const personalAddressLine2 = document.getElementById('personal_address_address_line_two');
      const personalCountry = document.getElementById('personal_address_country');
      const personalState = document.getElementById('personal_address_state');
      const personalCity = document.getElementById('personal_address_city');
      const personalPostCode = document.getElementById('personal_address_post_code');
      const personalPhone = document.getElementById('personal_address_phone');
      const personalAlternatePhone = document.getElementById('personal_address_contact');

      if (sameAsHeadOfficeCheckbox.checked) {
        personalAddressLine1.value = headOfficeAddressLine1;
        personalAddressLine2.value = headOfficeAddressLine2;
        personalCountry.value = headOfficeCountry;
        personalState.value = headOfficeState;
        personalCity.value = headOfficeCity;
        personalPostCode.value = headOfficePostCode;
        personalPhone.value = headOfficePhone;
        personalAlternatePhone.value = headOfficeAlternatePhone;

      } else {
        personalAddressLine1.value = '';
        personalAddressLine2.value = '';
        personalCountry.value = '';
        personalState.value = '';
        personalCity.value = '';
        personalPostCode.value = '';
        personalPhone.value = '';
        personalAlternatePhone.value = '';
      }
    });
  }
});
